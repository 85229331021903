import { FaInstagram, FaWhatsapp } from "react-icons/fa";

export const Config = {
  cardimgid: "img-7.jpg",
  profileimgid: "img-6.jpg",
  crm: "CRM/PR - 29243",
  rqe: "RQE 18893",
  contextGenre: "Saúde da mulher",
  maps: "https://goo.gl/maps/4vGaVgzU5KAvwT9A9",
  address:
    "R. Prof. Pedro Viriato Parigot de Souza, 600 Park Shopping Barigui, Piso L3 - Sala 3014 - Mossunguê, Curitiba - PR, 81200-100",

  socials: [
    {
      name: "Instagram",
      link: "https://www.instagram.com/dra_vanessa_amoroso/",
      icon: <FaInstagram />,
      bgimg: "linear-gradient(90deg, #e40fcc, #ffc200)",
    },
    {
      name: "WhatsApp",
      link: "https://api.whatsapp.com/send?phone=554191590603",
      icon: <FaWhatsapp />,
      bgimg: "linear-gradient(90deg, #00e576, #00a884)",
    },
  ],

  calls: {
    whatsapp: {
      text: "Whatsapp",
      link: "https://wa.me/554191590603?text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Dra.%20Vanessa%20Amoroso%20e%20gostaria%20de%20marcar%20uma%20consulta.%20",
      iconLink: "/icon/whatsapp.svg",
    },
    doctoralia: {
      text: "",
      link: "https://www.doctoralia.com.br/vanessa-amoroso/ginecologista/curitiba",
      iconLink: "/icon/doctoralia.svg",
    },
  },

  actuator: [
    {
      icon: "/svg/laser.svg",
      text: "Laser íntimo",
      description: (
        <>
          <p>
            O laser íntimo, também conhecido como rejuvenescimento vaginal a
            laser, é um procedimento não cirúrgico inovador que visa melhorar
            tanto a estética quanto a funcionalidade da região genital feminina.
            Ele ganhou popularidade nos últimos anos devido aos seus benefícios
            potenciais para a saúde e a qualidade de vida das mulheres.
          </p>
          <p>
            Este procedimento utiliza tecnologia laser, especificamente um laser
            de dióxido de carbono (CO2) fracionado, para tratar uma variedade de
            questões relacionadas à região vaginal. Aqui estão alguns dos
            principais benefícios e usos do laser íntimo:
          </p>
          <ul>
            <li>
              Rejuvenescimento Vaginal: O envelhecimento, o parto e outros
              fatores podem levar à perda de firmeza e elasticidade na região
              vaginal. O laser íntimo estimula a produção de colágeno na mucosa
              vaginal, restaurando a firmeza e a juventude da área.
            </li>
            <li>
              Incontinência Urinária: Muitas mulheres sofrem de incontinência
              urinária, especialmente após a gravidez e o parto. O laser íntimo
              pode fortalecer os músculos do assoalho pélvico, ajudando a
              melhorar o controle da bexiga.
            </li>
            <li>
              Atrofia Vaginal: A menopausa frequentemente causa atrofia vaginal,
              levando a sintomas desconfortáveis, como secura e dor durante o
              sexo. O laser íntimo ajuda a restaurar a saúde e a umidade
              vaginal.
            </li>
            <li>
              Lubrificação e Sensibilidade: O tratamento a laser pode aumentar a
              lubrificação natural e a sensibilidade da região, melhorando a
              resposta sexual e o prazer.
            </li>
            <li>
              Tratamento de Cicatrizes e Cicatrização: Além dos benefícios
              estéticos e funcionais, o laser íntimo também pode ser usado para
              tratar cicatrizes ou áreas de cicatrização na região genital.
            </li>
            <li>
              Procedimento Não Invasivo: Uma das grandes vantagens do laser
              íntimo é que ele é não cirúrgico, o que significa que não requer
              incisões, anestesia geral ou tempo de recuperação prolongado.
              Geralmente, é um procedimento ambulatorial que pode ser realizado
              em consultórios médicos.
            </li>
            <li>
              Resultados Graduais: Os resultados do tratamento a laser íntimo
              geralmente melhoram ao longo do tempo à medida que o colágeno
              continua a se regenerar. Isso significa que os efeitos benéficos
              podem se tornar mais evidentes nas semanas e meses seguintes ao
              tratamento.
            </li>
            <li>
              Segurança: Quando realizado por profissionais de saúde
              qualificados de preferencia Ginecologista, o laser íntimo é
              considerado um procedimento seguro.
            </li>
            <p>
              É importante ressaltar que os resultados podem variar de pessoa
              para pessoa, e a consulta com um médico experiente é fundamental
              para determinar a adequação do tratamento às necessidades
              individuais. O laser íntimo oferece uma opção não cirúrgica para
              abordar questões ginecológicas e melhorar a qualidade de vida das
              mulheres, tanto esteticamente quanto em termos de saúde íntima
            </p>
          </ul>
        </>
      ),
    },
    {
      icon: "/svg/ginecologia.svg",
      text: "Ginecologia ",
      description:
        "Sou uma médica ginecologista dedicada ao cuidado integral da saúde feminina. Com anos de experiência, estou comprometida em oferecer atendimento personalizado, diagnósticos precisos e tratamentos eficazes. Minha abordagem compassiva visa promover o bem-estar, a prevenção e o suporte em todas as fases da vida da mulher. Sinta-se à vontade para confiar em mim para todas as suas necessidades ginecológicas.",
    },
    {
      icon: "/svg/anticoncepcao.svg",
      text: "Anticoncepção",
      description:
        "Sou sua parceira confiável em saúde reprodutiva! Especialista em orientação de Anticoncepcionais orais e injetáveis, aplicação de métodos anticonceptivos como Implanon , DIU hormonal, DIU de cobre e prata. ",
    },
    {
      icon: "/svg/mastologia.svg",
      text: "Mastologia ",
      description:
        "Sou uma especialista em mastologia dedicado a oferecer cuidados excepcionais a mulheres com doenças mamárias. Com vasta experiência clínica e conhecimento atualizado, meu objetivo é fornecer diagnóstico preciso, tratamento personalizado e apoio compassivo. Trabalho em parceria com as pacientes para garantir o melhor resultado possível, promovendo a saúde das mamas e o bem-estar geral.",
    },
    {
      icon: "/svg/reconstrucaomamaria.svg",
      text: "Reconstrução mamária",
      description:
        "Sou uma experiente profissional, especialista em reconstrução mamária. Com vasta experiência e habilidades cirúrgicas de ponta, dedicada a restaurar a autoconfiança e a qualidade de vida das pacientes com câncer de mama. Meu compromisso com a excelência e a empatia inabalável tornam-me uma profissional respeitada nesse campo.",
    },
    {
      icon: "/svg/cirurgia.svg",
      text: "Cirurgias da mama",
      description:
        "Venha conhecer os avanços na cirurgia de mama! Como especialista, estou aqui para compartilhar informações atualizadas sobre procedimentos seguros e inovadores. Saiba mais sobre técnicas minimamente invasivas, reconstrução mamária e opções personalizadas de tratamento. Juntas, podemos cuidar da sua saúde mamária de forma eficaz e acolhedora. Agende sua consulta e descubra o que a medicina moderna pode oferecer para sua confiança e bem-estar. Estou ansiosa para cuidar de você! #CirurgiaDeMamaAvançada",
    },
    {
      icon: "/svg/book.svg",
      text: "Publicação de livros científicos",
      description: (
        <>
          <p>
            2019 - URBAN, CICERO; Spautz, Cléverton; Lima, Rubens; Schünemann,
            Eduardo; AMOROSO, VANESSA{" "}
            <b>IMMEDIATE BREAST RECONSTRUCTION IN PREGNANCY AND LACTATION</b>{" "}
            In: Oncoplastic and Reconstructive Breast Surgery.l ed.: Springer
            Internatlonal Publishing, 2019, p. 695.701.
          </p>

          <p>
            2018 - URBAN, C. A: Martins, T A; AMOROSO, V.{" "}
            <b>ESTADIAMENTO CLÍNICO E IMAGINOLÓGICO DO CANCER DE MAMA</b> In:
            Mama.l ed.: Elsevier, 2018, v.l. p. 447-453.
          </p>

          <p>
            2018 - AMOROSO, V.; URBAN, C. A.{" "}
            <b>
              GRAVIDEZ Após TRATAMENTO PARA CÂNCER DE MAMA: QUANDO LIBERAR PARA
              ENGRAVIDAR E SEGURANÇA ONCOLOGICA?
            </b>{" "}
            In Controvérsias em Câncer de Mama, 2018, v.l. p.438. ISBN
            978-85-509-0340-8
          </p>
        </>
      ),
    },
  ],

  name: "Dra. Vanessa Amoroso",
  description:
    "Ginecologia • Anticoncepção • Mastologia • Reconstrução mamária • Laser íntimo",

  biography: [
    "Sou professora do curso de medicina, médica formada em 2011 pela Universidade Positivo, com especialização em Ginecologia e Obstetrícia pelo Hospital Universitário Evangélico Mackenzie de Curitiba em 2015. Possuo mestrado em Biotecnologia Industrial pela mesma universidade, o que me proporcionou conhecimentos sobre a aplicação da tecnologia na área da saúde.  Também sou especialista em Mastologia pelo Hospital Nossa Senhora das Graças, em Curitiba, e membro da Sociedade Brasileira de Mastologia.",

    "Desde 2018, atuo como docente no curso de Medicina das Faculdades Pequeno Príncipe, lecionando a disciplina de Habilidades Médicas e Simulação. Minha experiência como professora me permite compartilhar meus conhecimentos e contribuir para a formação de médicos mais capacitados.",

    "Além de Minha atividade docente, faço parte do corpo clínico do Hospital Universitário Evangélico Mackenzie, onde trabalho nas áreas de Ginecologia e Obstetrícia, bem como no Ambulatório de Reconstrução Mamária. minha atuação nesses campos permite que aplique meu conhecimento e contribua para melhorar a qualidade de vida de minhas pacientes.",

    "Sou especialista em Laser Íntimo certificado pelo renomado Cetrus SP, uma instituição de excelência reconhecida nacionalmente pela sua expertise nessa área."
  ],
};
