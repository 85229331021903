import { useEffect, useState } from "react";
import styled from "styled-components";
import responsive from "../functions/Responsive";
const Card = styled.div`
  box-shadow: 1px 1px 6px rgb(0, 0, 0, 0.2);
  align-items: center;
  margin: auto;
  background-color: var(--primary-color-base);
  color: white;

  @media (max-width:480px) {
    margin: 0;
    width: 100vw;
    padding-inline: 0;
    margin: 0;
    padding: 0;
  }
`;

const Content = styled.div`
  height: 20rem;
  display: flex;
  padding-inline: 1rem;
  

  @media (max-width:480px) {
    margin: 0;
    padding-inline: 0;

    max-width: 100vw;
  }

  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;


  p {

    max-width: 20rem;


    opacity: 70%;
    font-size: 14px;
    font-weight: 500;

  }
  h3,
  p {

    width: 100%;
    text-align: center;
  }
  h3{
    font-size: 1.8rem;
    margin-block: 0;
    font-weight: 700;
  }
  h1{
    text-transform: uppercase;
    margin: 0;
    margin-block-end: 1rem;
    color: var(--grey-color-secundary);
    opacity: 70%;

    font-size: 1.2rem;

  }
`;
const Socials = styled.div`
  gap: 5px;
  display: flex;
  justify-content: center;

  margin-top: 1rem;

  a {
    -webkit-transition: 0.3s;
    &:hover {
      opacity: 50%;
    }
    svg {
      color: var(--grey-color-secundary);
      margin-inline: 0.2rem;
      width: 1.3rem;
      height: 1.3rem;
      cursor: pointer;
    }
  }
`;
const ImgCard = styled.div`
  width: 23rem;
  height: 20rem;
  background-size: cover;
  margin: 0;

  @media (max-width:480px) {
    width: 100%;
    
  }
`;

export default function CardUser(props = { data: Object }) {
  const [resp, setResp] = useState("");
  useEffect(() => {
    responsive({ min: 800, setState: setResp });
  }, []);
  const { socials, name, description, cardimgid, crm, rqe, contextGenre } = props.data;
  return (
    <Card style={{ display: resp }}>
      <Content>
        <h3>{name}</h3>
        <p>{description}</p>

        <h1>{contextGenre}</h1>

        <b>{crm} • {rqe}</b>

        <Socials>
          {socials.map((scl, i) => (
            <a
              href={scl.link}
              key={i}
              id={`btn_${scl.name.toLowerCase()}_carduser`}
            >
              {scl.icon}
            </a>
          ))}
        </Socials>
      </Content>
      <ImgCard
        style={{ backgroundImage: `url('/img/gallery/${cardimgid}')` }}
      ></ImgCard>
    </Card>
  );
}
